import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import OptinForm from "./components/OptinForm";
import { LinearProgress } from "@mui/material";
import { external } from "../../services/linkHelper";
import { useLocation, useNavigate, useParams } from "react-router";
import WelcomeComponents from "../Welcome/components/index";
import GeneralWelcomeComponents from "../GeneralWelcome/components";
import { saveLead } from "../../services/apis/generalLeads/requests";
import AnalyticScripts from "../../components/Generics/Scripts";
import {
  getHotLeadBusinessGroupConfig,
  getLead,
  leadCheckIn,
} from "../../services/apis/generalLeads/fetchs";
import { removerCaracteresEspeciais, validarCPF } from "../../services/utils";

export default function GeneralWelcome({ disabledByTest = null }) {
  const location = useLocation();
  const params = useParams();

  const navigate = useNavigate();
  const [invalidCPF, setInvalidCPF] = useState(false);
  const invalidPhone = false;
  const [customerData, setCustomerData] = useState({
    name: "",
    cpf: "",
    email: null,
    phone: "",
    bornAt: null,
    gender: "",
    useTerms: false,
  });

  const cpfUnmasked = removerCaracteresEspeciais(customerData.cpf);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [loadingForm, setloadingForm] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [leadExistedData, setLeadExistedData] = useState({});

  const [businessGroupName, setBusinessGroupName] = useState("");
  const [promotionDisabled, setPromotionDisabled] = useState(
    disabledByTest ?? true
  );
  const [loadingPromotion, setLoadingPromotion] = useState(true);

  const handleBusinessGroupName = (bgName) => {
    if (!bgName) return "Shopping";
    const splitedByHyphen = bgName.split("-");

    if (splitedByHyphen.length === 1) {
      return "Shopping";
    }

    if (splitedByHyphen[1]?.includes("Outlet")) {
      return splitedByHyphen[1];
    }

    if (!splitedByHyphen[1]?.includes("Shopping")) {
      return `Shopping ${splitedByHyphen[1]}`;
    }

    return splitedByHyphen[1];
  };

  const fetchStatus = async () => {
    if (!params?.id) {
      setPromotionDisabled(true);
      setLoadingPromotion(false);
      return;
    }

    try {
      setLoadingPromotion(true);
      const resReq = await getHotLeadBusinessGroupConfig({
        businessGroupId: params.id,
      });
      const res = resReq?.data?.leadConfig;

      setBusinessGroupName(
        handleBusinessGroupName(res?.businessGroupName || "")
      );
      setPromotionDisabled(!res?.promotionEnabled);
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoadingPromotion(false);
    }
  };

  useEffect(() => {
    if (disabledByTest === null) {
      fetchStatus();
    } else {
      setLoadingPromotion(false);
    }
  }, []);

  function isCPFValid() {
    const cpf = document.getElementsByName("cpf")[0] || {
      value: customerData.cpf,
    };
    const cpfUnmasked = removerCaracteresEspeciais(cpf.value ?? "");
    if (cpfUnmasked.length === 0) {
      setInvalidCPF(true);
      cpf.classList?.add("input-required-invalid");
      return false;
    } else if (cpfUnmasked.length === 11) {
      cpf.classList?.remove("input-required-invalid");
      setInvalidCPF(!validarCPF(cpfUnmasked) && cpfUnmasked !== "99999999999");
      return validarCPF(cpfUnmasked);
    }
  }

  function isNameValid() {
    let name = document.getElementsByName("name")[0];
    if (name.value.length > 2) {
      name.classList.remove("input-required-invalid");
      setErrorMsg("");
      return true;
    }
    name.classList.add("input-required-invalid");
    setErrorMsg("nome inválido");
    return false;
  }

  function isPhoneValid() {
    let phone = document.getElementsByName("phone")[0];
    const phoneUnmasked = removerCaracteresEspeciais(phone.value ?? "");
    if (phoneUnmasked.length === 11) {
      phone.classList.remove("input-required-invalid");
      setErrorMsg("");
      return true;
    }
    phone.classList.add("input-required-invalid");
    setErrorMsg("celular inválido");
    return false;
  }

  function isUseTermsValid() {
    if (customerData.useTerms) {
      setErrorMsg("");
      return true;
    }
    setErrorMsg("É necessário aceitar os termos");
    return false;
  }

  const registerCheck = async () => {
    const formData = {};
    let isFormValid =
      isNameValid() && isCPFValid() && isPhoneValid() && isUseTermsValid();

    const inputList = Array.prototype.slice.call(
      document.getElementsByTagName("input")
    );

    inputList.forEach(function (elem) {
      if (
        !elem.value &&
        (elem.name === "phone" ||
          elem.name === "name" ||
          (customerData.gender === "other" && elem.name === "genderOther"))
      ) {
        elem.classList.add("input-required-invalid");
        isFormValid = false;
      } else {
        formData[elem.name] = elem.value;
        if (elem.name !== "phone" && elem.name !== "cpf") {
          elem.classList.remove("input-required-invalid");
        }
      }
    });

    if (isFormValid) {
      try {
        setloadingForm(true);

        formData.groupId = parseInt(params.id);
        formData.cpf = customerData.cpf;
        formData.bornAt = customerData?.bornAt
          ? moment(customerData?.bornAt).format("YYYY-MM-DD")
          : null;
        formData.registeredByCustomer = true;
        formData.gender = formData.gender === "" ? null : formData.gender;

        await saveLead({
          ...customerData,
          cpf: cpfUnmasked,
          phone: removerCaracteresEspeciais(customerData.phone),
          gender: customerData?.genderOther || customerData.gender,
          businessGroupId: params.id,
        });

        const resCheckIn = await leadCheckIn({
          cpf: cpfUnmasked,
          businessGroupId: params.id,
        });

        return navigate(
          `${external.GSDEPARTMENTS}/${params.id}${location.hash}`,
          {
            state: {
              id: params.id,
              cpf: customerData.cpf,
              name: customerData?.name || "",
              isNewOptIn: true,
              requestData: formData,
              checkInData: resCheckIn?.data?.leadCheckIn,
            },
          }
        );
      } catch (err) {
        console.error(err.message);
      } finally {
        setloadingForm(false);
      }
    }
  };

  const changeCustomerData = (e, isCheckBox = false) => {
    let tempData = { ...customerData };
    tempData[e.target.name] = !isCheckBox ? e.target.value : e.target.checked;
    setCustomerData(tempData);
  };

  const checkCustomerGSHotLeadExists = async (cpf) => {
    try {
      let res;
      try {
        res = await getLead({
          businessGroupId: parseInt(params.id),
          cpf,
        });
      } catch (err) {
        console.error(err.message);
      }

      if (res?.data?.lead?.cpf) {
        setLeadExistedData(res?.data?.lead);
        setCustomerData((old) => ({
          ...old,
          name: res?.data?.lead?.name,
          phone: res?.data?.lead?.phone,
          email: res?.data?.lead?.email,
          gender: res?.data?.lead?.gender || "",
          bornAt: res?.data?.lead?.bornAt
            ? moment(res?.data?.lead?.bornAt).toDate()
            : null,
        }));
        await leadCheckIn({ cpf: cpfUnmasked, businessGroupId: params.id });
        setShowRegisterForm(true);
      } else {
        setShowRegisterForm(true);
      }
    } catch (err) {
      if (
        JSON.parse(err.request.response)?.error?.message?.includes("not found")
      ) {
        setShowRegisterForm(true);
      }
    }
  };

  const findCustomerByCpf = async (cpf) => {
    try {
      setloadingForm(true);
      await checkCustomerGSHotLeadExists(cpf);
    } catch (err) {
      console.error(err.message);
    } finally {
      setloadingForm(false);
    }
  };

  useEffect(() => {
    const cpf = removerCaracteresEspeciais(customerData.cpf);
    if (cpf.length < 11) return;
    if (!isCPFValid() && cpfUnmasked !== "99999999999") return;

    findCustomerByCpf(cpf);
  }, [customerData.cpf]);

  const handleFormContent = () => {
    if (promotionDisabled) {
      return (
        <GeneralWelcomeComponents.PromotionDisabled
          businessGroupName={businessGroupName || "Shopping"}
        />
      );
    }

    if (showRegisterForm) {
      return (
        <GeneralWelcomeComponents.GeneralOptinForm
          invalidPhone={invalidPhone}
          changeCustomerData={changeCustomerData}
          errorMsg={errorMsg}
          setCustomerData={setCustomerData}
          customerData={customerData}
          leadExistedData={leadExistedData}
        />
      );
    }

    return (
      <GeneralWelcomeComponents.CpfForm
        setInvalidCPF={setInvalidCPF}
        loadingForm={loadingForm}
        customerData={customerData}
        changeCustomerData={changeCustomerData}
        invalidCPF={invalidCPF}
      />
    );
  };

  if (loadingPromotion) {
    return (
      <LinearProgress
        sx={{
          backgroundColor: "white",
          "& .MuiLinearProgress-bar": { backgroundColor: "#C4C4C4" },
        }}
      />
    );
  }

  return (
    <>
      <AnalyticScripts groupId={params.id} />
      <div className="welcome-page">
        <>
          <WelcomeComponents.Logo
            data={{}}
            loading={false}
            isGeneral={true}
            groupId={parseInt(params.id)}
          />
          <div className="welcome-content">
            <div className="welcome-right">
              {!promotionDisabled && (
                <span style={{ fontSize: 18, lineHeight: "140%" }}>
                  Quer receber as ofertas que estão ativas AGORA de acordo com
                  os departamentos que você veio ver hoje? Cadastre-se já!
                </span>
              )}
              <OptinForm
                customerData={customerData}
                registerCheck={registerCheck}
                showRegisterForm={showRegisterForm}
                handleFormContent={handleFormContent}
                loadingForm={loadingForm}
                loading={false}
                isGeneralGroup={true}
              />
            </div>
          </div>
        </>
      </div>
      <footer className="welcome-footer-gen" />
    </>
  );
}

GeneralWelcome.propTypes = {
  disabledByTest: PropTypes.bool,
};
