import React from "react";
import Clarity from "./Clarity";
import GoogleTagManager from "./GoogleTagManager";

export default function AnalyticScripts({ groupId }) {
  return (
    <>
      <Clarity groupId={groupId} />
      <GoogleTagManager groupId={groupId} />
    </>
  );
}
