import React from "react";
import { Helmet } from "react-helmet-async";

export default function Clarity({ groupId }) {
  const tokens = {
    3235: "qfiz7i4dn8",
    2444: "qghjt06n0o",
  };

  const clarityToken = Object.keys(tokens).includes(groupId)
    ? tokens[groupId]
    : "mtjes57rbp";

  return (
    <Helmet>
      <script type="text/javascript">
        {`(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${clarityToken}");`}
      </script>
    </Helmet>
  );
}
