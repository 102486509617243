import React from "react";
import { Helmet } from "react-helmet-async";

export default function GoogleTagManager({ groupId }) {
  const tokens = {
    3235: "G-9YB2C2YN1T",
    2444: "G-55ED5D569C",
    3228: "G-E63QRZE3V3"
  };

  const googleTagToken = Object.keys(tokens).includes(groupId)
    ? tokens[groupId]
    : "G-84H7XG5PV1";

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleTagToken}`}
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "${googleTagToken}");`}
      </script>
    </Helmet>
  );
}
