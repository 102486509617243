import React from "react";
import router from "./routes";
import { RouterProvider } from "react-router";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider, QueryClient } from "react-query";
import { HelmetProvider } from "react-helmet-async";

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <RouterProvider router={router} />
          <ReactQueryDevtools />
        </HelmetProvider>
      </QueryClientProvider>
    </div>
  );
}
export default App;
